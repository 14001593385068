import React, { memo } from 'react';
import Datepicker from '@components/Datepicker/Datepicker';
import { useField } from 'formik';
import FormControl from "@mui/material/FormControl";
import { FormHelperText, useTheme } from "@mui/material";

const FormikDatepicker = ({ name, fullWidth, handleChange, datepickerProps, ...rest }) => {
  const theme = useTheme();
  const [field, state, { setValue }] = useField(name);

  const onChange = (val) => {
    setValue(val);
    handleChange && handleChange(val);
  };

  const defaultProps = {
    ...field,
    selected: (field.value && new Date(field.value)) || null,
    onChange,
    fullWidth,
  };

  console.log("Datepicker", state.error, state.touched)
  return (
    <FormControl error={!!state.error && state.touched}>
      <Datepicker
        datepickerProps={{ ...defaultProps, ...datepickerProps }}
        error={!!state.error && state.touched}
        {...rest}
      />
      {!!state.error && state.touched && (
        <FormHelperText sx={{ ml: '14px', color: theme.palette.secondary.dark }}>
          {!!state.error && state.touched && state.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(FormikDatepicker);
