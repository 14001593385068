export default {
  base: {
    cancel: 'Cancel',
    save: 'Save',
    confirm: 'Confirm',
    close: 'Close',
    search: 'Search',
    login: 'Sign in',
    register: 'Register',
    submit: 'Submit',
    edit: 'Edit',
    delete: 'Delete',
    city: 'City',
    seeAll: 'See all',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    unknownUser: 'Guest',
  },
  lang: {
    ua: 'Ukrainian',
    ru: 'Russian',
    en: 'English',
    es: 'Spanish',
  },
  currencies: {
    uah: 'UAH',
  },
  types: {
    user: {
      phone: 'Phone',
      email: 'E-mail',
      password: 'Password',
      oldPassword: 'OLD Password',
      newPassword: 'NEW Password',
      confirmPassword: 'Confirm password',
      firstName: 'First name',
      lastName: 'Last name',
      confirm: '<base.confirm>',
      avgBillAmount: 'Average bill',
      restaurant: 'Restaurant',
      city: '<base.city>',
      lang: 'Language used',
    },
    admin: {
      '@extends': 'types.user',
      lastLogin: 'Last login',
      role: 'Role',
      roles: {
        admin: 'Administrator',
        analyst: 'Analyst',
        owner: 'Owner',
      },
    },
    place: {
      name: 'Title',
      description: 'Description',
      address: 'Address',
      latitude: 'Latitude',
      longitude: 'Longitude',
      active: 'Active',
      link: 'Link',
      cusines: 'Cusines',
      moods: 'Moods',
      payments: 'Type of payments',
      city: '<base.city>',
      web: 'Web-site',
      phone: 'Phone',
      time: 'Time',
      contactPhone: 'Phone for notifications',
    },
    placeOpenHours: {
      sun: 'Sunday',
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
    },
    placeHall: {
      name: 'Title',
      active: 'Active',
      length: '<base.length> (m)',
      width: '<base.width> (m)',
      order: 'Order',
    },
    placeSpot: {
      type: 'Type',
      label: 'Label',
      seats: 'Seats',
      minSeats: 'Minimum seats',
      length: '<base.length> (m)',
      width: '<base.width> (m)',
      priority: 'Priority',
      angle: 'Angle',
      oneSeat: 'Single',
      twoSeat: 'Double',
      border: 'Border',
      borderType: {
        solid: 'Solid',
        dashed: 'Dashed',
        dotted: 'Dotted',
      },
    },
    cities: {
      kyiv: 'Kyiv',
      odessa: 'Odessa',
    },
    reservation: {
      accepted: 'Accepted',
      requested: 'New',
      success: 'Successful',
      rejected: 'Rejected',
      cancelled: 'Cancelled',
      skipped: 'Skipped',
      finished: 'Finished',
      waitlist: 'Wait list',
      inProgress: 'In progress',
      totalReserves: 'Reservations',
      successReservations: 'Successful reservations',
      failedReservations: 'Unsuccessful reservations',
      comment: 'Comment',
      hall: 'Hall',
      spot: 'Table',
      spots: 'Tables',
      from: 'Start',
      to: 'Finish',
      seats: 'Number of persons',
      event: 'Event',
      events: {
        other: 'Other',
        business: 'Business meeting',
        date: 'Date',
        family: 'Family meeting',
        friends: 'Meeting with friends',
        birthday: 'Birthday',
        football: 'Football',
      },
      keepTable: 'Do not transfer',
      manager: 'Administrator',
      waitForTomorrow: 'Transfer to the next day',
    },
    dish: {
      name: 'Dish name',
      categories: 'Categories',
      photo: 'Photo',
      description: 'Description',
      weight: 'Weight',
      price: 'Price, UAH',
      active: 'Active',
      vegetarian: 'Vegetarian',
      hot: 'Hot',
      top: 'Top',
    },
    dishCategory: {
      name: 'Category name',
      parent: 'Parent category',
      order: 'Order',
    },
    legal: {
      privacy: 'Privacy policy',
      privacyWhat: 'Privacy policy',
      terms: 'Terms of use',
      termsWhat: 'Terms of use',
    },
    channel: {
      name: 'Name',
    },
    certificate: {
      active: 'Active',
      name: 'Name',
      category: 'Category',
      categoryCertificate: 'Certificate',
      categorySubscription: 'Subscription',
      price: 'Price',
      description: 'Description',
      photo: 'Photo',
      type: 'Type',
      typeVisits: 'For number of visits',
      typeAmount: 'For amount',
      visits: 'Number of visits',
      amount: 'Amount',
      expiration: 'Validity',
      expirationDate: 'Until certain date',
      expirationDays: 'Days from purchase',
      tillDate: 'Until which date',
      validDays: 'Number of days',
      activation: 'Activation date',
      activationPurchase: 'From purchase',
      activationVisit: 'After first visit',
      activationVisitMaxDays: 'Automatically activate after (days)',
      duration: 'Subscription period (days)',
    },
  },
  auth: {
    Login: {
      fields: { '@extends': 'types.user' },
      forgotPassword: 'Forgot Password?',
      title: 'Log in',
      errors: {
        '@any': 'An error occurred!',
        '@extends': 'errors.validation',
        wrongAuth: 'Wrong phone or password',
        wrongRole: "You don't have premissions to access to this service. Write to administrator",
      },
      submit: '<base.login>',
      dontHaveAccount: 'Sign up!',
      createAccount: 'Create an account',
      rememberMe: 'Remember me',
      or: 'or',
    },
    Register: {
      fields: { '@extends': 'types.user' },
      legal: { '@extends': 'types.legal' },
      cities: { '@extends': 'types.cities' },
      title: 'Register',
      errors: {
        '@any': 'An error occurred!',
        '@extends': 'errors.validation',
        confirm: 'You have to accept terms',
      },
      haveAccount: 'Already have an account?',
      confirm: 'I accept',
      and: 'and',
      submit: '<base.register>',
      login: '<base.login>',
    },
    Social: {
      signIn: 'Sign in with',
      providers: {
        google: 'Google',
        facebook: 'Facebook',
      },
    },
    Forgot: {
      fields: { '@extends': 'types.user' },
      errors: {
        '@any': 'An error occurred!',
        '@extends': 'errors.validation',
        userWithSpecifiedEmailNotFoundd: 'Account with the specified email was not found',
      },
      login: '<base.login>',
      title: 'Recover your password',
      buttonSend: 'Send reset link',
      goBackLogin: 'Go back to login',
    },
  },
  errors: {
    validation: {
      '@root': 'Wrong format',
      '@any': '<.>',
      format: '<.>',
      required: 'This field is required',
      password: 'The password is too short',
      email: 'Please, use correct email format',
      phone: 'Please, use correct phone format: +380999999999',
      minLength: 'Minimum length is <=symbols> symbols',
      maxLength: 'Maximum length is <=symbols> symbols',
      number: 'The value must be a number',
      minNumber: 'Minimum value is <=min>',
      maxNumber: 'Maximum value is <=max>',
      validationError: 'Input dataset is not correct in fields: <=fields>',
      notUniq: 'Is not uniq',
      url: 'Link must be a valid URL',
      passwordsMatch: 'Passwords do not match',
    },
    badRequest: 'Try one more time or write to administrators',
    socialUserAlreadyExist: 'User already attached to another account',
    global: {
      somethingWrong: 'Something went wrong',
      refresh: 'Refresh the page, and if the problem persists, contact our team at nosenkocc@gmail.com',
    },
    menuHasDishes: 'You cannot delete categories that have dishes',
    menuHasChild: 'You cannot delete categories with subcategories inside',
  },
  components: {
    Select: {
      selectAll: 'Select all',
    },
    Sidebar: {
      superadmin: 'Superadmin',
      home: 'Home',
      reservations: 'Reservations',
      timeline: 'Timeline',
      clients: 'Guests',
      halls: 'Halls',
      channels: 'Sales channels',
      menu: 'Menu',
      reviews: 'Reviews',
      statistics: 'Statistics',
      payment: 'Payment',
      incomingPayments: 'Incoming payments',
    },
    DashboardMenu: {
      reservation: 'Reservation',
      channels: 'Sales',
      top10: 'TOP-10',
      reviews: 'Reviews',
    },
    Reservations: {
      title: 'Reservations',
      hall: 'Hall',
      table: 'Table',
      accept: 'Accept',
      reject: 'Reject',
      rejectReasonTitle: 'Indicate the reject reason',
      rejectReason: 'Reject reason',
      rejectNoPlacesShort: 'Populate text: No free spots',
      rejectNoPlaces: 'There are no free spots for the selected time',
      customDeclineMsg: 'Add custom message',
      confirm: 'Reject',
      finish: 'Finish',
      finishingReservation: 'Finishing reservation',
      confirmFinishReservation: 'Are you sure you want to mark this reservation as completed successfully?',
      billAmount: 'Bill amount',
      indicateBillAmount: 'Indicate bill amount',
      skip: 'Skipped',
      skippingReservation: 'Reservation was skipped',
      confirmSkipReservation: 'Are you sure you want to mark this reservation as skipped by the guest?',
      updateBillAmount: 'Update bill amount',
      updatingBill: 'Updating bill amount',
      showHistory: 'Show history',
      clientCancelled: 'Guest canceled the reservation',
      cancellingReservation: 'Cancelling reservation',
      confirmCancelReservation:
        'Are you sure you want to cancel this reservation on behalf of the guest? This should only be done if the customer has requested to cancel the reservation.',
      movingToInProgress: 'The guest came to the restaurant',
      backToAccepted: 'Back to accepted',
      confirmBackToAccepted: 'Are you sure you want to move this reservation back to the accepted list?',
      confirmMoveToInProgress: 'Do you confirm that the guest has come to the restaurant?',
      additionalConfirmation: 'Confirm reservation',
      confirmAdditionalConfirmation: 'Are you sure you want to confirm this reservation?',
      deleteFromWaitList: 'Remove from wait list',
      confirmDeleteFromWaitList: 'Are you sure you want to remove this entry from the wait list?',
      errors: { '@extends': 'errors.validation' },
      filter: { '@extends': 'types.reservation' },
      seeAll: '<base.seeAll>',
      acceptingReservation: 'Accepting reservation',
      confirmAcceptReservation: 'Are you sure that you want to confirm this reservation?',
      today: 'Today',
      tomorrow: 'Tomorrow',
      people: 'pers.',
      callClient: 'Call guest',
      disableDay: 'Close reservations',
      enableDay: 'Open reservations',
      disablingDay: 'Closing reservations',
      enablingDay: 'Opening reservations',
      showOnlyUnconfirmed: 'Show only unconfirmed',
      onlyThisHall: 'Reservations only in this hall',
      confirmDisableDay:
        'Are you sure you want to close the reservations for the current day? Customers will no longer be able to make a reservation for today until you manually disable this option. This setting will be automatically turned off tomorrow morning.',
      confirmEnableDay:
        'Are you sure you want to open the reservations for the current day? Customers will be able to make reservations for today again.',
      dayClosedWarning:
        "Reservations have been disabled for the current day. New customers won't be able to make reservations for today.",
      comment: 'Comment: ',
      cancelReason: 'Cancel reason: ',
      cancelReasons: {
        changeOfPlans: 'My plans have changed',
        mistake: 'I have made this booking by a mistake',
        late: "I'm late and can't make it on specified time",
        changeTime: 'I want to choose a different time',
        adminCancelled: 'Administrator canceled the reservation as requested by the guest',
      },
      unknownUser: '<base.unknownUser>',
      indicateHallAndTable: 'Indicate hall and table',
      edit: '<base.edit>',
      pickSpot: 'Pick a spot',
      spotNotFound: 'Could not find free spot for a selected time',
      events: { '@extends': 'types.reservation.events' },
      minutes: 'm',
      hours: 'h',
      uah: '<currencies.uah>',
      viewMenu: 'View menu',
    },
    Channels: {
      title: 'Channels',
      noChannels: 'There are no channels yet',
      copyButtonLabel: 'copy',
      promoLabel: 'Promo',
      activeLabel: 'Active',
      name: 'Name',
      successReservations: 'Successful',
      unfinishedReservations: 'Unfinished',
      addChannel: 'Add new channel',
      update: 'Update channel',
      copiedSuccessfully: 'Url copied to clipboard',
      seeAll: '<base.seeAll>',
      addNewChannel: 'Adding new channel',
      fields: { '@extends': 'types.place' },
      errors: { '@extends': 'errors.validation' },
      deletingChannel: 'Deleting channel',
      confirmDeleteChannel: 'Are you sure you want to delete this sales channel?',
      system: {
        placeReservations: {
          name: 'Administrator',
          description: 'Manually created reservations',
        },
        guestReservations: {
          name: 'Reservble Marketplace',
          description: 'Reservations',
        },
      },
    },
    ChannelStatistics: {
      '@extends': 'types.reservation',
      backToChannels: 'Back to channels',
      newUsers: 'New users',
      chartTitle: 'Sales channel statistics',
    },
    Top10: {
      attendanceTitle: 'Top-10 Attendance',
      attendanceLabel: 'Visits',
      checkTitle: 'Top-10 Receipt',
      checkLabel: 'Average receipt',
      noData: 'Not enough data to display statistics',
    },
    Settings: {
      settings: 'Settings',
      description: 'Description',
      characteristics: 'Characteristics',
      reservations: 'Reservations',
      gallery: 'Gallery',
      hours: 'Working hours',
      contacts: 'Contacts',
      administration: 'Administration',
      halls: 'Halls',
      integrations: 'Integrations',
      deposits: 'Deposits',
      bookingWidget: 'Reservation widget',
      promo: 'Promo campaigns',
      certificates: 'Certificates',
      fields: { '@extends': 'types.place' },
      hourFields: { '@extends': 'types.placeOpenHours' },
      inactive: 'Inactive',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.settings' },
      cancel: '<base.cancel>',
      save: '<base.save>',
      contactForm: {
        fields: {
          type: 'Type',
          reference: 'Value',
          referencePlaceholder: 'Fill in the field',
        },
        errors: {
          '@any': 'An error occurred!',
          '@extends': 'errors.validation',
        },
        types: {
          EMAIL: 'Email',
          PHONE: 'Phone',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          WEB: 'Web',
        },
        submit: 'Add contact',
        update: 'Update contact',
      },
      paidServices: {
        title: 'Additional services',
        active: 'Active',
        label: 'Button name',
      },
    },
    Clients: {
      title: 'Guests',
      visits: 'Number of visits',
      cheque: 'Average cheque',
      addClient: 'Add',
      addNewClient: 'Adding new guest',
      editingClient: 'Editing guest',
      cancel: '<base.cancel>',
      save: '<base.save>',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.clients' },
      sort: {
        date: 'By add date',
        cheque: 'By average cheque',
        visits: 'By number of visits',
      },
      lang: { '@extends': 'lang' },
    },
    Pagination: {
      of: 'of',
    },
    SearchInput: {
      search: '<base.search>',
    },
    Administration: {
      title: 'Administration',
      fields: { '@extends': 'types.admin' },
      addAdmin: 'Add administrator',
      addNewAdmin: 'Adding new administrator',
      editingAdmin: 'Editing administrator',
      cancel: '<base.cancel>',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.administrations' },
      deletingAdmin: 'Deleting administrator',
      confirmDeleteAdmin: 'Are you sure you want to delete this admin?',
    },
    Integrations: {
      telegramService: 'Telegram API',
      telegramKey: 'Telegram user or group ID',
      googleService: 'Google My Business',
      googleKey: 'Place ID',
      facebookService: 'Facebook Business Manager',
      facebookKey: 'Page Access Token',
      syrveService: 'Syrve',
      syrveKey: 'API key',
      syrveOrganization: 'Organization',
      syrveTerminal: 'Main terminal',
      syrveApiFailed: 'Wrong API key',
      loadSyrveMenu: 'Load menu',
      portmoneService: 'Portmone',
      portmoneKey: 'Portmone Payee ID',
      connect: 'Connect',
      reset: 'Reset form',
      loadSyrveOrganisations: 'Download organizations',
      loadSyrveTerminals: 'Download terminals',
      connected: 'Connected',
      telegramConnectionFailed:
        'Could not send a message to the chat with the provided ID. Send @reservble_bot a private message to receive individual notifications, or add the bot to a group to receive notifications in this group. After that, try again.',
      googleApiFailed: 'Invalid place ID',
      enableBanquetPreOrder: 'Enable banquet synchronization',
      enableSyncReservations: 'Enable Reserve Sync',
      errors: { '@extends': 'errors.validation' },
    },
    ReservationSettings: {
      title: 'Reservations',
      ourManagement: 'Allow online reservations when all spots are occupied',
      autoSeating: 'Automatic confirmation',
      tgAdminNotification: 'Missed Reservations Reminder',
      smsConfirmation: 'Send SMS message with reservation confirmation',
      smsFeedback: 'Send SMS message with feedback request after successful reservation',
      newReservationMessage: 'Send Telegram message when new reservation is created in admin panel',
      reservationCancelMessage: 'Send Telegram message when reservation is declined or cancelled',
      showHalls: 'Allow customers to select hall during the online reservation',
      reservationDuration: 'Default reservation duration, minutes',
      reservationAllDay: 'Till the end of the day',
      lastTimeReservation1: 'Accept last reservation',
      lastTimeReservation2: 'minutes before closing',
      highlightReservations: 'Highlight reservations before start',
      minutesBefore: 'How many minutes before?',
      hideTableCapacity: 'Do not show table capacity',
      disableSameTimeReservation: 'Do not allow creating reservations for the same time',
      showAutomaticReservationButton: 'Show button for automatically picking a spot',
      syrveDelay: 'When closing the bill in Syrve, mark table as available after',
      depositsActive: 'Activate deposits',
      depositsPaymentTime: 'Deposit payment time, minutes',
      onlyDepositReservations: 'Allow creating reservations only with deposits',
      menuActive: 'Activate menu',
      clientMenuActive: 'Also show menu on client',
      certificatesActive: 'Show certificates on client',
      customDeclineMsg: 'Custom reject reservation message',
      minutes: 'minutes',
      save: '<base.save>',
      success: '<actions.settings.saved>',
      title2: 'Additional information for reservations',
      customTagsTitle: 'Overriding tags',
      hourFields: { '@extends': 'types.placeOpenHours' },
      apply: 'Apply',
      message: {
        ruPlaceholder: 'Русский',
        uaPlaceholder: 'Українська',
        enPlaceholder: 'English',
        ruDefault: 'В связи с большой загрузкой действует ограничение в 2 часа на длительность резерва',
        uaDefault: "У зв'язку з великим завантаженням діє обмеження у 2 години на тривалість резерву",
        enDefault: 'Due to busy hours, there is a 2-hour limit for the reservations',
      },
      highloadTitle: 'Additional options',
      adminSelection: 'Choose administrator when creating a reservation',
      showAllReservations: 'Allow all table reservations to be shown at once',
      showReservationConfirmed: 'Additional confirmation of reservations',
      showWaitList: 'Show wait list',
      reservationMissed: 'Automatically cancel reservations if the guest does not arrive after',
      reservationMissedTime: 'minutes',
      showHallFilter: 'Allow to filter reservations by halls',
    },
    BookingWidget: {
      styleTitle: 'Style settings',
      backgroundColor: 'Background color',
      borderColor: 'Border color',
      primaryColor: 'Primary color',
      primaryColorHover: 'Primary color on hover',
      textColor: 'Text color',
      contrastTextColor: 'Text contrast color',
      errorColor: 'Error color',
      configTitle: 'Other settings',
      maxGuests: 'Maximum number of guests',
      showSlots: 'Show available time slots',
      showHalls: 'Show halls for selection',
      translations: 'Text elements',
      policyTitle: 'Policy title',
      policyText: 'Main policy text',
      policyItems: 'Policy items',
      finishMessage: 'Message after completing reservation',
      save: '<base.save>',
      lang: { '@extends': 'lang' },
      success: '<actions.settings.saved>',
    },
    Deposits: {
      addDeposit: 'Add category',
      addType: 'Add period',
      active: 'Active',
      depositPlaceholder: 'Category name',
      typePlaceholder: 'Period name',
      amountInUah: 'Indicate deposit amount for given day in',
      amountInUahPlaceholder: 'Amount in UAH',
      uah: '<currencies.uah>',
      days: { '@extends': 'types.placeOpenHours' },
      save: '<base.save>',
      success: '<actions.settings.saved>',
      errors: { '@extends': 'errors.validation' },
      minOneType: 'Category should contain at least one period',
      deletingDeposit: 'Deleting category',
      confirmDeleteDeposit: 'Are you sure you want to delete this category? This action cannot be undone',
      deletingType: 'Deleting period',
      confirmDeleteType: 'Are you sure you want to delete this period? This action cannot be undone',
    },
    Promo: {
      title: 'Promo campaigns',
      current: 'Current promo',
      save: '<base.save>',
      noPromo: 'Absent',
      percent: 'Percent',
      errors: { '@extends': 'errors.validation' },
      success: '<actions.settings.saved>',
    },
    Halls: {
      title: 'Place halls',
      addNewHall: 'Add hall',
      editHall: '<base.edit>',
      activeHall: 'Active hall',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      spotsForm: {
        fields: { '@extends': 'types.placeSpot' },
        errors: {
          '@any': 'An error occurred!',
          '@extends': 'errors.validation',
        },
        duplicate: 'Duplicate',
        delete: 'Delete',
        submit: 'Create a spot',
        update: 'Update a spot',
        backToHalls: 'Halls',
        types: { ELLIPSE: 'Circle', RECT: 'Rectangle', LOUNGE: 'Lounge' },
        ifZeroMinSeats: "If 0 value will be taken from 'Seats' field",
        manualChairs: 'Manual chairs arrangement',
        top: 'Top',
        bottom: 'Bottom',
        left: 'Left',
        right: 'Right',
        couch: 'Couch',
        active: 'Active',
      },
      hallForm: {
        addNewHall: 'Adding new hall',
        editHall: 'Editing hall',
        fields: { '@extends': 'types.placeHall' },
        cancel: '<base.cancel>',
        save: '<base.save>',
        errors: { '@extends': 'errors.validation' },
        pattern: 'Hall background',
        patterns: {
          none: 'None',
          asphalt: 'Asphalt',
          stone: 'Stone',
          tiles: 'Tiles',
          wood: 'Wood',
        },
        bgCustom: 'Custom image',
      },
      reservationForm: {
        addingReservation: 'Creating reservation',
        editingReservation: 'Editing reservation',
        cancel: '<base.cancel>',
        save: '<base.save>',
        fields: {
          user: { '@extends': 'types.user' },
          reservation: { '@extends': 'types.reservation' },
        },
        errors: { '@extends': 'errors.validation' },
        pickSpot: 'Pick a spot',
        spotNotFound: 'Could not find free spot for a selected time',
        addToWaitList: 'Add to wait list',
        sameTimeReservationDisabled: 'Creating reservations for the same time is prohibited by the administrator',
        openMenu: 'Add dishes',
      },
      deletingSpot: 'Deleting spot',
      confirmDeleteSpot: 'Are you sure you want to delete this spot?',
      deletingHall: 'Deleting hall',
      confirmDeleteHall: 'Are you sure you want to delete this hall? All spots will also be deleted',
      accept: 'Accept',
      assigningReservation: 'Assigning reservation',
      assignText: 'Are you sure you want to accept this reservation and assign it to selected table?',
      reassignText:
        'Do you want to move this reservation to a different table, or link additional table to a reservation?',
      reassign: 'Move',
      link: 'Link table',
      cancel: '<base.cancel>',
      tableReservations: 'Reservations of table',
      close: '<base.close>',
      createReservation: 'Quick reservation',
      newWalkIn: 'New walk-in',
      indicateSeats: 'Indicate number of people',
      indicateFinish: 'Reservation ends',
      unknownUser: '<base.unknownUser>',
      showPast: 'Show finished reservations',
      hideReservations: 'Hide reservation list',
    },
    Profile: {
      title: 'User profile',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.profile' },
      save: '<base.save>',
      logout: 'End session',
    },
    PeriodsSelector: {
      select: {
        today: 'Today',
        yesterday: 'Yesterday',
        thisWeek: 'This week',
        lastWeek: 'Last week',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        thisYear: 'This year',
        lastYear: 'Last year',
      },
      orChoosePeriod: 'Or choose a period',
    },
    Reviews: {
      google: 'Google reviews',
      facebook: 'Facebook reviews',
      reservble: 'Reservble reviews',
      seeAll: '<base.seeAll>',
      connectGoogle: 'Indicate place ID from Google My Business to see reviews from Google',
      connectFacebook: 'Connect Facebook Business Manager to see reviews from Facebook',
      connect: 'Connect',
      noReviews: 'You did not receive any reviews here yet',
    },
    UserModal: {
      birthday: 'Birthday date',
      gender: 'Gender',
      familyStatus: 'Family status',
      comment: 'Comment',
      save: '<base.save>',
      preferences: 'Preferences',
      favorite: 'Favorite dishes',
      dontLike: "Don't like",
      allergies: 'Allergies',
      cuisines: 'Favorite cuisines',
      statistics: 'Statistics',
      ourPlace: 'Our restaurant',
      allPlaces: 'All restaurants',
      visits: 'Total visits',
      people: 'Average number of persons',
      averageBill: 'Average bill',
      reservationHistory: 'Reservation history',
      showAll: 'Show all',
    },
    RestaurantStatistics: {
      '@extends': 'types.reservation',
      regularClients: 'Regular guests',
      total: 'Total',
      regular: 'Regular',
      reviews: '<=service> reviews',
      reviewsNumber: {
        1: 'review',
        2: 'reviews',
        5: 'reviews',
      },
      details: 'Details',
      onlineReservations: 'Online reservations',
      handReservations: 'Reservations created manually',
      newUsers: 'New guests',
      chartTitle: 'Restaurant statistics',
    },
    Payment: {
      title: 'Billing',
      pay: 'Make a payment',
      currentBalance: 'Current balance',
      balanceDetails:
        'This is your current balance. A breakdown of the charges for the current billing period is available below. The debit will occur at the end of the current billing period',
      currentBillType: 'Current bill type',
      billingPeriod: 'Billing period',
      chatbot: 'Facebook/Instagram Chatbot',
      menu: 'Menu',
      tariffPayment: 'Basic tariff',
      reservationsInTariff: 'Reservations in tariff',
      reservationsUsed: 'Reservations used',
      reservationsOverTariff: 'Payment for additional reservations',
      smsTotal: 'Payment for sms',
      commission: 'Payment system commission (<=commission>%)',
      total: 'Total',
      paymentHistory: 'Payment history',
      invoice: 'Invoice',
      payment: 'Payment',
      invoiceDetails: 'Invoice details',
      invoicesHistory: 'Invoice history',
      invoiceBillType: 'Tariff',
      makingPayment: 'Making a payment',
      selectAmount: 'Indicate the amount',
      orSelect: 'Or choose the period',
      INPROCESS: 'To be paid',
      DONE: 'Paid',
      DONE_PAYMENT: 'Account replenished',
      months: {
        1: 'One month',
        2: '2 months',
        3: '3 months',
        6: '6 months',
        9: '9 months',
        12: '12 months',
      },
      proceed: 'Proceed',
      errors: { '@extends': 'errors.validation' },
    },
    SuperAdmin: {
      menu: {
        organizations: 'Organizations',
        places: 'Places',
        cities: 'Cities',
        tags: 'Tags',
        invoices: 'Invoices',
      },
      organizations: {
        addOrganization: 'Add organization',
        title: 'Available organizations',
        addingOrganization: 'Adding organization',
        editingOrganization: 'Editing organization',
        deletingOrganization: 'deleting organization',
        confirmDeleteOrganization: 'Are you sure you want to delete this organization from the system?',
        fields: {
          name: 'Name',
          created: 'Date created',
        },
        errors: { '@extends': 'errors.validation' },
      },
      places: {
        withTarrifs: 'With tarrifs',
        addRestaurant: 'Add restaurant',
        title: 'Available restaurants',
        activate: 'Activate',
        deactivate: 'Deactivate',
        addingRestaurant: 'Adding restaurant',
        editingRestaurant: 'Editing restaurant',
        deletingRestaurant: 'Deleting restaurant',
        confirmDeleteRestaurant:
          'Are you sure you want to delete this restaurant from the system? This will erase all data related to it. This action is irreversible.',
        activatingRestaurant: 'Activating restaurant',
        deactivatingRestaurant: 'Deactivating restaurant',
        confirmActivateRestaurant: 'Are you sure you want to activate this restaurant?',
        confirmDeactivateRestaurant:
          'Are you sure you want to deactivate this restaurant? It will no longer be show on a guest website, and all related accounts will temporarily be disabled.',
        fields: {
          name: 'Name',
          ownerName: 'Owner name',
          ownerLastName: 'Owner last name',
          ownerPhone: 'Owner phone',
          active: 'Active',
          billType: 'Tariff',
          created: 'Date created',
          charityBill: 'Total bill',
          organization: 'Organization',
        },
        sort: {
          date: 'By add date',
          name: 'By name',
        },
        errors: { '@extends': 'errors.validation' },
      },
      cities: {
        addCity: 'Add city',
        title: 'Available cities',
        addingCity: 'Adding city',
        editingCity: 'Editing city',
        fields: {
          id: 'ID',
          code: 'Name',
        },
        errors: { '@extends': 'errors.validation' },
      },
      tags: {
        addTag: 'Add tag',
        title: 'Available tags',
        addingTag: 'Adding tag',
        editingTag: 'Editing tag',
        fields: {
          id: 'ID',
          name: 'Name',
          type: 'Type',
          search: 'Search',
        },
        errors: { '@extends': 'errors.validation' },
      },
      invoices: {
        title: 'New invoices',
        approve: 'Approve',
        approvingInvoices: 'Approving invoices',
        confirmApproveInvoices: 'Are you sure you want to approve all selected invoices?',
        editingInvoice: 'Editing invoice',
        fields: {
          id: 'ID',
          place: 'Place',
          date: 'Date',
          amount: 'Amount',
          adjustment: 'Adjustment',
          status: 'Status',
          comment: 'Comment',
          confirm: '<base.confirm>'
        },
        errors: { '@extends': 'errors.validation' },
      },
    },
    NoAccess: {
      message: 'Your current plan does not include access to this page. To change the tariff, please contact ',
      admin: 'administrator',
    },
    ReservationHistoryModal: {
      close: '<base.close>',
      title: 'Reservation changelog',
      date: 'Date',
      time: 'Time',
      user: 'User',
      action: 'Action',
      guest: '<base.unknownUser>',
      new: 'Reservation was created',
      billRemoved: 'El depósito fue eliminado',
      changed: 'Reservation was updated',
      status: {
        REQUESTED: 'Guest created a new reservation',
        ACCEPTED: 'Reservation was accepted',
        INPROGRESS: 'Guest came to the restaurant',
        REJECTED: 'Reservation was rejected by the administrator',
        FINISHED: 'Reservation was marked as finished',
        SKIPPED: 'Guest did not come',
        CANCELED: 'Reservation was cancelled by the guest',
      },
      fields: { '@extends': 'types.reservation' },
    },
    Dishes: {
      title: 'Menu',
      addCategory: 'Add category',
      edit: '<base.edit>',
      delete: '<base.delete>',
      addDish: 'Add dish',
      uploadPhoto: 'Upload photo',
      addingCategory: 'Adding category',
      editingCategory: 'Editing category',
      addingDish: 'Adding dish',
      editingDish: 'Editing dish',
      deletingCategory: 'Deleting category',
      confirmDeleteCategory: 'Are you sure you want to delete this category from the menu?',
      deletingDish: 'Deleting dish',
      confirmDeleteDish: 'Are you sure you want to delete this dish? It will no longer be available',
      uah: '<currencies.uah>',
      category: {
        '@extends': 'types.dishCategory',
      },
      dish: {
        '@extends': 'types.dish',
      },
      errors: {
        '@extends': 'errors.validation',
      },
    },
    ConflictingReservationsModal: {
      title: 'Another reservation exists for this table',
      cancel: '<base.cancel>',
      confirm: '<base.confirm>',
      description:
        'Are you sure you want to set reservation for this table and date/time? This slot is already occupied by the following reservations:',
    },
    OverduePaymentPopup: {
      message: 'The restaurant has an unpaid bill. Top up your balance on the payment page',
    },
    Hours: {
      notWorkingDays: 'Not working days',
      selectDate: 'Select a date',
      addDate: 'Add date',
      dateList: 'List of not working days',
    },
    DepositManagement: {
      switch: 'Deposit',
      total: 'Total',
      quantity: 'Qty',
      price: 'Price',
      uah: '<currencies.uah>',
    },
    DepositStatistics: {
      name: 'Name',
      phone: 'Phone number',
      hall: 'Hall',
      spot: 'Spot',
      billId: 'Payment ID',
      amount: 'Amount',
      total: 'Total',
      uah: '<currencies.uah>',
    },
    MenuModal: {
      title: 'Menu',
      search: 'Enter dish name',
      uah: '<currencies.uah>',
      cancel: '<base.cancel>',
      save: 'Add to reservation',
      preview: 'Preview',
      total: 'Total',
      prepayment: 'Prepayment amount',
    },
    PreviewMenuModal: {
      title: 'Dishes added to order',
      total: 'Total',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
    },
    DishCard: {
      uah: '<currencies.uah>',
      qty: 'Qty',
    },
    DishCategoriesMenu: {
      edit: '<base.edit>',
      delete: '<base.delete>'
    },
    CertificateSettings: {
      addCertificate: 'Create certificate',
      addingCertificate: 'Adding certificate',
      editingCertificate: 'Editing certificate',
      deletingCertificate: 'Deleting certificate',
      confirmDeleteCertificate: 'Are you sure you want to delete this certificate? It will be unavailable in the future',
      uploadPhoto: 'Upload photo',
      fields: { '@extends': 'types.certificate', },
      errors: { '@extends': 'errors.validation' },
    },
    CertificateCard: {
      '@extends': 'types.certificate',
      active: 'Active',
      inactive: 'Inactive',
      days: 'days',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
      delete: '<base.delete>',
    },
  },
  actions: {
    place: {},
    hall: {
      saved: 'Changes saved',
      hallAdded: 'New hall was added successfully',
      spotDeleted: 'Spot deleted',
    },
    settings: {
      saved: 'Settings saved',
      menuConnected: 'Menu successfully loaded',
    },
    clients: {
      added: 'Guest added',
      exist: 'User <=displayedName> already exist',
    },
    administrations: {
      added: 'User added',
      exist: 'User added under name <=displayedName>',
      edited: 'User rights edited',
      deleted: 'User rights removed',
    },
    profile: {
      saved: 'Changes saved',
      passwordChanged: 'Password was changed successfully',
      oldPasswordIsInvalid: 'Old password is not correct',
    },
    reservation: {
      success: 'Reservation updated successfully',
      alreadyBookedTable: 'We already have reservation on same time for this table',
      busySlotForReservation: 'Creating reservations for the same time is prohibited by the administrator',
    },
  },
  service: {
    jwt: {
      invalidToken: 'Invalid accessToken',
      accessExpired: 'Access token expired',
      signinInProcess: 'Signin in process...',
      welcomeMessage: 'Welcome to RESERVBLE Resto UI',
      loginWithTokenFailed: 'Login with token is failed',
    },
  },
};
